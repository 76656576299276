<template>
  <div class="main-page">
    <p>이메일 문의 : sajupick@kakao.com</p>
    <p>테스트 운영 중입니다.</p>
  </div>
</template>

<script>
export default {
  name: "MainPage",
};
</script>

<style scoped>
.main-page {
  margin: 10px;
}
</style>
