<template>
  <div class="member-page">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="center" align="center">
                <v-btn icon outlined @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div v-if="type === 'MANSE' && manse">
                  <manse-format-view :manse="manse" :number="Number(manse.fortune.big)" :memberId="memberId" />
                </div>
                <div v-if="type === 'SAJU' && saju">
                  <v-row justify="center" align="center">
                    <v-col>
                      <v-text-field v-model="year" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickYearMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickYearPlus">+</v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="month" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickMonthMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickMonthPlus">+</v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="day" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickDayMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickDayPlus">+</v-btn>
                    </v-col>
                  </v-row>

                  <saju-fortune-format-view
                    :user="saju.userManse"
                    :saju="saju.saju"
                    :positions="[]"
                    :comment2="comment"
                  />
                </div>
                <br />
                <br />
                <v-btn icon outlined @click="(dialog = false), (type = ''), (year = new Date().getFullYear())">
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <member-add-form @addMember="onClickAddMember" />

    <v-row justify="center" align="center">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">번호</th>
              <th class="text-left">멤버</th>
              <th class="text-left">타입</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody v-if="members">
            <member-list-view
              @emitSaju="onClickSaju"
              @emitManse="onClickManse"
              v-for="(member, index) in members.memberList"
              :key="member.id"
              :member="member"
              :page="page"
              :index="members.totalItems - index - (page - 1) * 10"
            />
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <br />
    <template>
      <div class="text-center" v-if="members">
        <v-pagination v-model="page" :length="members.totalPages" :total-visible="5"></v-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import memberListView from "./MemberListView";
import memberAddForm from "./MemberAddForm";
import ManseFormatView from "../format/ManseFormatView";
import SajuFortuneFormatView from "../format/SajuFortuneFormatView";

export default {
  name: "memberPage",
  data() {
    return {
      page: 1,
      dialog: false,
      year: Number(this.$moment().format("YYYY")),
      month: Number(this.$moment().format("MM")),
      day: Number(this.$moment().format("DD")),
      comment: "",
      type: "",
      memberId: "",
      number: this.manse ? Number(this.manse.fortune.big) : 0,
    };
  },
  components: {
    memberListView,
    memberAddForm,
    ManseFormatView,
    SajuFortuneFormatView,
  },
  watch: {
    page: {
      deep: true,
      async handler() {
        await this.getMembers(this);
      },
    },
    year: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
    month: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
    day: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
  },

  async created() {
    await this.getMembers(this);
  },
  methods: {
    onClickSaju(payload) {
      const { memberId, dialog, type } = payload;
      this.memberId = memberId;
      this.dialog = dialog;
      this.type = type;
      this.getSaju(this);
    },
    onClickManse(payload) {
      const { memberId, dialog, type } = payload;
      this.memberId = memberId;
      this.dialog = dialog;
      this.type = type;
      this.getManse(this);
    },
    onClickYearPlus() {
      if (this.year + 1 > 2100) {
        alert("2100 보다 작거나 같아야 합니다.");
      }
      this.year = this.year + 1;
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickYearMinus() {
      if (this.year - 1 < 1900) {
        alert("1900 보다 크거나 같아야 합니다.");
      }
      this.year = this.year - 1;
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickMonthPlus() {
      if (this.month === 12) {
        this.year += 1;
        this.month = 1;
      } else {
        this.month = this.month + 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickMonthMinus() {
      if (this.month === 1) {
        this.year -= 1;
        this.month = 12;
      } else {
        this.month = this.month - 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickDayPlus() {
      //31  : 1월 3월 5월 7월 8월 10월 12월
      //30  : 4월 6월 9월 11월
      //28  : 2월
      if (
        (this.month === 1 ||
          this.month === 3 ||
          this.month === 5 ||
          this.month === 7 ||
          this.month === 8 ||
          this.month === 10 ||
          this.month === 12) &&
        this.day === 31
      ) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else if ((this.month === 4 || this.month === 6 || this.month === 9 || this.month === 11) && this.day === 30) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else if (this.month === 2 && this.day === 28) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else {
        this.day = this.day + 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickDayMinus() {
      if (
        (this.month === 1 ||
          this.month === 2 ||
          this.month === 4 ||
          this.month === 6 ||
          this.month === 8 ||
          this.month === 9 ||
          this.month === 11) &&
        this.day === 1
      ) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 31;
        } else {
          this.month -= 1;
          this.day = 31;
        }
      } else if ((this.month === 5 || this.month === 7 || this.month === 10 || this.month === 12) && this.day === 1) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 30;
        } else {
          this.month -= 1;
          this.day = 30;
        }
      } else if (this.month === 3 && this.day === 1) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 28;
        } else {
          this.month -= 1;
          this.day = 28;
        }
      } else {
        this.day = this.day - 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    async onClickAddMember(payload) {
      await this.addMember(payload);
      await this.getMembers(this);
    },
    ...mapActions(["addMember", "getMembers", "getSaju", "getManse"]),
  },
  computed: {
    ...mapState(["members", "saju", "manse"]),
  },
};
</script>

<style scoped>
.container {
  max-width: 100vw;
  padding: 3px;
  margin: 3px;
}
.manse-format-view {
  margin: 10px;
}
</style>
