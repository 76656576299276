export default {
  accessToken: "",
  me: null, //내 정보,
  saju: "", //사주
  manse: "", //만세력
  members: "", //멤버
  memberList: "", //멤버 리스트 (드롭다운)
  chemy: "", //궁합
  fortune: "", //운세
  groups: "", //그룹 리스트
  groupMembers: "", //그룹 별 멤버 리스트
  groupNames: "", //그룹명 리스트
};
