<template>
  <div class="my-page">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="center" align="center">
                <v-btn icon outlined @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div v-if="type === 'MANSE' && manse">
                  <manse-format-view :manse="manse" :number="Number(manse.fortune.big)" :memberId="memberId" />
                </div>
                <div v-if="type === 'SAJU' && saju">
                  <v-row justify="center" align="center">
                    <v-col>
                      <v-text-field v-model="year" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickYearMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickYearPlus">+</v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="month" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickMonthMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickMonthPlus">+</v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="day" hide-details single-line type="number" readonly />
                      <v-btn x-small outlined @click="onClickDayMinus">-</v-btn>
                      <v-btn x-small outlined @click="onClickDayPlus">+</v-btn>
                    </v-col>
                  </v-row>
                  <saju-fortune-format-view
                    :user="saju.userManse"
                    :saju="saju.saju"
                    :positions="[]"
                    :comment2="comment"
                  />
                </div>
                <br />
                <br />
                <v-btn icon outlined text @click="(dialog = false), (type = ''), (year = new Date().getFullYear())">
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <div class="space">
      {{ me.formatUser.user.birthday }}({{ me.formatUser.user.age }}세,
      <span v-if="me.formatUser.user.gender === 'male'">남</span><span v-else>여</span>,
      <span v-if="me.formatUser.user.birthdayType === 'SOLAR'">양력</span><span v-else>음력</span>)
      <span v-if="me.formatUser.user.time"> {{ me.formatUser.user.time }}</span>
      <br />
      <v-row class="space"
        ><saju-format-view :saju="me.formatUser.saju" />
        <fortune-format-view :fortune="me.formatUser.fortune" />
      </v-row>
    </div>
    <v-row> </v-row>

    <v-row class="button"
      ><v-btn outlined small class="space" @click="onClickSaju">사주 분석</v-btn
      ><v-btn outlined small class="space" @click="onClickManse">만세력 보기</v-btn></v-row
    >
    <br />
    <br />
    <br />
    <br />
    <br />

    <v-btn outlined small @click="doCopy('https://sajupick.com')">https://sajupick.com 링크 복사!</v-btn>
    <div class="space">
      <div>다른 디바이스 사용 및 로그아웃 전에 uuid 별도 보관이 필요합니다.</div>
      <v-btn outlined small @click="doCopy(me.uuid)">{{ me.uuid }} 복사!</v-btn>
      <br />
      <br />
      <v-btn outlined @click="onClickSignOut" small>로그아웃</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ManseFormatView from "../format/ManseFormatView";
import SajuFormatView from "../format/SajuFormatView";
import FortuneFormatView from "../format/FortuneFormatView";
import SajuFortuneFormatView from "../format/SajuFortuneFormatView";

export default {
  name: "MyPage",
  data() {
    return {
      message: "https://sajupick.com",
      uuid: this.me ? this.me.uuid : "",
      dialog: false,
      year: Number(this.$moment().format("YYYY")),
      month: Number(this.$moment().format("MM")),
      day: Number(this.$moment().format("DD")),
      comment: "",
      type: "",
      memberId: "",
      number: this.manse ? Number(this.manse.fortune.big) : 0,
    };
  },
  components: {
    ManseFormatView,
    SajuFormatView,
    FortuneFormatView,
    SajuFortuneFormatView,
  },
  watch: {
    year: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
    month: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
    day: {
      deep: true,
      async handler() {
        await this.getSaju(this);
      },
    },
  },

  methods: {
    doCopy: function(message) {
      this.message = message;
      this.$copyText(this.message).then(
        function(e) {
          alert("Copied");
          console.log(e);
        },
        function(e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },

    onClickSignOut() {
      this.signout().then(() => {
        this.$router.push({ path: "/" });
      });
    },
    onClickYearPlus() {
      if (this.year + 1 > 2100) {
        alert("2100 보다 작거나 같아야 합니다.");
      }
      this.year = this.year + 1;
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickYearMinus() {
      if (this.year - 1 < 1900) {
        alert("1900 보다 크거나 같아야 합니다.");
      }
      this.year = this.year - 1;
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickMonthPlus() {
      if (this.month === 12) {
        this.year += 1;
        this.month = 1;
      } else {
        this.month = this.month + 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickMonthMinus() {
      if (this.month === 1) {
        this.year -= 1;
        this.month = 12;
      } else {
        this.month = this.month - 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickDayPlus() {
      //31  : 1월 3월 5월 7월 8월 10월 12월
      //30  : 4월 6월 9월 11월
      //28  : 2월
      if (
        (this.month === 1 ||
          this.month === 3 ||
          this.month === 5 ||
          this.month === 7 ||
          this.month === 8 ||
          this.month === 10 ||
          this.month === 12) &&
        this.day === 31
      ) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else if ((this.month === 4 || this.month === 6 || this.month === 9 || this.month === 11) && this.day === 30) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else if (this.month === 2 && this.day === 28) {
        if (this.month === 12) {
          this.year += 1;
          this.month = 1;
          this.day = 1;
        } else {
          this.month += 1;
          this.day = 1;
        }
      } else {
        this.day = this.day + 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickDayMinus() {
      if (
        (this.month === 1 ||
          this.month === 2 ||
          this.month === 4 ||
          this.month === 6 ||
          this.month === 8 ||
          this.month === 9 ||
          this.month === 11) &&
        this.day === 1
      ) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 31;
        } else {
          this.month -= 1;
          this.day = 31;
        }
      } else if ((this.month === 5 || this.month === 7 || this.month === 10 || this.month === 12) && this.day === 1) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 30;
        } else {
          this.month -= 1;
          this.day = 30;
        }
      } else if (this.month === 3 && this.day === 1) {
        if (this.month === 1) {
          this.year -= 1;
          this.month = 12;
          this.day = 28;
        } else {
          this.month -= 1;
          this.day = 28;
        }
      } else {
        this.day = this.day - 1;
      }
      this.comment = this.year + "-" + this.month + "-" + this.day;
      this.comment = "";
    },
    onClickSaju() {
      this.memberId = this.me.memberId;
      this.type = "SAJU";
      this.dialog = true;
      this.getSaju(this);
    },
    onClickManse() {
      this.memberId = this.me.memberId;
      this.type = "MANSE";
      this.dialog = true;
      this.getManse(this);
    },
    ...mapActions(["signout", "getSaju", "getManse"]),
  },
  computed: {
    ...mapState(["me", "saju", "manse"]),
  },
};
</script>

<style scoped>
.container {
  max-width: 100vw;
  padding: 3px;
  margin: 3px;
}
.my-page {
  margin: 1px;
}
.space {
  margin: 3px;
}
.button {
  margin: 20px;
}
.name {
  margin: 5px;
}
.manse-format-view {
  margin: 10px;
}
</style>
