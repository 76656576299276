import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_SAJU,
  SET_MANSE,
  SET_MEMBERS,
  SET_MEMBER_LIST,
  SET_CHEMY,
  SET_FORTUNE,
  SET_GROUPS,
  SET_GROUP_MEMBERS,
  SET_GROUP_NAMES,
} from "./mutations-types";
import api from "@/api";

export default {
  /**
   * [인증]
   */
  //회원가입
  async signup({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    const { birthday, birthdayType, gender, time } = payload;
    const object = time
      ? {
          gender,
          birthdayType,
          birthday,
          time,
        }
      : {
          gender,
          birthdayType,
          birthday,
        };
    try {
      try {
        const res = await api.post("/user/signup", object);
        const { accessToken } = res.data.data;
        commit(SET_ACCESS_TOKEN, accessToken);
      } catch (err) {
        if (err.response.status === 400) {
          //유효성 검증 실패
          return alert("생년월일 또는 시간을 다시 입력해주세요.");
        } else if (err.response.status === 404) {
          return alert(err.response.data.error.message);
        }
      }

      try {
        const res = await api.get("/user/me");
        if (res) {
          console.log(res.data.data.userInfo);
          commit(SET_MY_INFO, res.data.data.userInfo);
        }
      } catch (error) {
        this.router.push({ name: "SignUpPage" });
      }
    } catch (error) {
      alert("회원가입 에러");
    }
  },

  //로그인
  async signin({ commit }, payload) {
    const { uuid, birthday } = payload;
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
    try {
      try {
        const res = await api.post("/user/signin", {
          uuid,
          birthday,
        });

        const { accessToken } = res.data.data;
        commit(SET_ACCESS_TOKEN, accessToken);
      } catch (err) {
        if (err.response.status === 400) {
          //유효성 검증 실패
          return alert("uuid 또는 생년월일를 다시 입력해주세요.");
        } else if (err.response.status === 401) {
          //잘못된 입력 uuid 또는 생년월일
          return alert("uuid 또는 생년월일를 다시 입력해주세요.");
        }
      }

      try {
        const res = await api.get("/user/me");
        if (res) {
          commit(SET_MY_INFO, res.data.data.userInfo);
        }
      } catch (error) {
        this.router.push({ name: "SignInPage" });
      }
    } catch (error) {
      alert("로그인 에러");
    }
  },

  async signinByToken({ commit }, token) {
    commit(SET_ACCESS_TOKEN, token);

    try {
      await api.get("/user/log");

      return await api.get("/user/me").then((res) => {
        commit(SET_MY_INFO, res.data.data.userInfo);
      });
    } catch (error) {
      this.router.push({ name: "SignUpPage" });
    }
  },

  //로그아웃
  signout({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },

  //궁합
  async getChemy({ commit }, payload) {
    try {
      const { memberId, targetId } = payload;
      const res = await api.get(`/chemy/member/${memberId}/target/${targetId}`);
      if (res) {
        return commit(SET_CHEMY, res.data.data);
      }
    } catch (err) {
      return alert("궁합 가져오기 에러");
    }
  },

  //운세
  async getFortune({ commit }, payload) {
    try {
      const { memberId, year } = payload;
      const res = await api.get(`/fortune/member/${memberId}/year/${year}`);
      if (res) {
        return commit(SET_FORTUNE, res.data.data);
      }
    } catch (err) {
      return alert("운세 가져오기 에러");
    }
  },

  //멤버 리스트 (드롭다운)
  async getMemberList({ commit }) {
    try {
      const res = await api.get(`/member/list`);
      if (res) {
        return commit(SET_MEMBER_LIST, res.data.data);
      }
    } catch (err) {
      return alert("멤버 리스트 가져오기 에러");
    }
  },

  //사주
  async getSaju({ commit }, payload) {
    try {
      const { memberId, year, month, day } = payload;
      const res = await api.get(`/saju/${memberId}/year/${year}/${month}/${day}`);
      if (res) {
        return commit(SET_SAJU, res.data.data);
      }
    } catch (err) {
      if (err.response.status === 400) {
        return alert("잘못된 memberId, year, month, day 를 입력하였습니다.");
      } else if (err.response.status === 404) {
        return alert(err.response.data.error.message);
      } else if (err.response.status === 429) {
        return alert("Too many requests");
      } else {
        return alert("사주 가져오기 에러");
      }
    }
  },

  //만세력
  async getManse({ commit }, payload) {
    try {
      const { memberId, number, number2 } = payload;
      let res = null;

      if (number && number2) {
        res = await api.get(`/manse/${memberId}/${number}/${number2}`);
      } else if (number) {
        res = await api.get(`/manse/${memberId}/${number}`);
      } else {
        res = await api.get(`/manse/${memberId}`);
      }
      if (res) {
        return commit(SET_MANSE, res.data.data);
      }
    } catch (err) {
      if (err.response.status === 400) {
        return alert("잘못된 memberId를 입력하였습니다.");
      } else if (err.response.status === 404) {
        return alert(err.response.data.error.message);
      } else {
        return alert("만세력 가져오기 에러");
      }
    }
  },

  /**
   * [멤버 관리]
   */
  //멤버 리스트
  async getMembers({ commit }, payload) {
    try {
      let { page } = payload;
      page -= 1;
      const res = await api.get(`/member?page=${page}&size=10`);

      if (res) {
        return commit(SET_MEMBERS, res.data.data);
      }
    } catch (err) {
      return alert("멤버 리스트 에러");
    }
  },

  //멤버 추가
  async addMember({ commit }, payload) {
    commit;
    const { gender, birthdayType, birthday, time } = payload;
    const object = time
      ? {
          gender,
          birthdayType,
          birthday,
          time,
        }
      : {
          gender,
          birthdayType,
          birthday,
        };
    try {
      await api.post("/member", object);
    } catch (err) {
      if (err.response.status === 400) {
        return alert("생년월일 또는 시간을 다시 입력해주세요.");
      } else if (err.response.status === 404) {
        return alert(err.response.data.error.message);
      } else {
        return alert("멤버 추가 에러");
      }
    }
  },

  //멤버 삭제
  async deleteMember({ commit }, id) {
    commit;
    try {
      return api.delete(`/member/${id}`);
    } catch (err) {
      if (err.response.status === 400) {
        return alert("잘못된 memberId를 입력하였습니다.");
      } else if (err.response.status === 403) {
        return alert(err.response.data.error.message);
      } else if (err.response.status === 404) {
        return alert(err.response.data.error.message);
      } else {
        return alert("멤버 삭제 에러");
      }
    }
  },

  /**
   * [그룹 관리]
   */
  //그룹 리스트
  async getGroups({ commit }, payload) {
    try {
      let { page } = payload;
      page -= 1;
      const res = await api.get(`/group?page=${page}&size=10`);
      if (res) {
        return commit(SET_GROUPS, res.data.data);
      }
    } catch (err) {
      return alert("그룹 리스트 에러");
    }
  },

  //그룹 추가
  async addGroup({ commit }, payload) {
    commit;
    const { name } = payload;
    try {
      return await api.post("/group", {
        name,
      });
    } catch (err) {
      return alert("그룹 추가 에러");
    }
  },

  //그룹 수정
  async updateGroup({ commit }, payload) {
    commit;
    const { id, name } = payload;
    try {
      return await api.patch(`/group/${id}`, {
        name,
      });
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("그룹 수정 에러");
      }
    }
  },

  //그룹 삭제
  async deleteGroup({ commit }, id) {
    commit;
    try {
      return api.delete(`/group/${id}`);
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("그룹 삭제 에러");
      }
    }
  },

  //그룹명 리스트
  async getGroupNames({ commit }) {
    try {
      const res = await api.get(`/group/names`);
      if (res) {
        return commit(SET_GROUP_NAMES, res.data.data);
      }
    } catch (err) {
      return alert("그룹명 리스트 에러");
    }
  },

  /**
   * [그룹-멤버 관리]
   */
  //그룹-멤버 리스트
  async getGroupMembers({ commit }, payload) {
    let { groupId, page } = payload;
    page -= 1;
    try {
      const res = await api.get(`/group/${groupId}/member?page=${page}&size=10`);

      if (res) {
        return commit(SET_GROUP_MEMBERS, res.data.data);
      }
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("그룹 별 멤버리스트 에러");
      }
    }
  },

  //그룹에 멤버 추가
  async addMemberToGroup({ commit }, payload) {
    commit;
    const { memberId, groupId } = payload;

    try {
      await api.post(`/group/${groupId}/member/${memberId}`, {
        memberId,
        groupId,
      });
      return alert("그룹에 멤버 추가 성공");
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else if (err.response.status === 409) {
        return alert(err.response.data.message);
      } else {
        return alert("그룹에 멤버 추가 에러");
      }
    }
  },

  //그룹에서 멤버 제거
  async removeMemberFromGroup({ commit }, payload) {
    commit;
    const { memberId, groupId } = payload;
    try {
      return await api.delete(`/group/${groupId}/member/${memberId}`);
    } catch (err) {
      if (err.response.status === 403) {
        return alert(err.response.data.message);
      } else {
        return alert("그룹에서 멤버 제거 에러");
      }
    }
  },
};
