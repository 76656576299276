import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import "babel-polyfill";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import VueGtag from "vue-gtag";
import AdFit from "vue-adfit-component";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(AdFit.Banner);
Vue.use(VueMomentJS, moment);
Vue.config.productionTip = false;

const savedToken = Cookies.get("accessToken");
if (savedToken) {
  store.dispatch("signinByToken", savedToken);
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// 구글 애널리틱스
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-XE5M6PBD2T", // Google Analytics의 Tracking ID를 넣어준다
      params: { send_page_view: false },
    },
  },
  router
);
