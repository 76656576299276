<template>
  <div class="chemy-format-view">
    <div v-if="user">
      <div class="fixed-bar">
        <span
          >{{ user.user.birthday }} (<span v-if="user.user.gender === 'male'">남</span><span v-else>여</span>,
          {{ user.user.age }}세)</span
        >
        ||
        <span
          >{{ target.user.birthday }} (<span v-if="target.user.gender === 'male'">남</span><span v-else>여</span>,
          {{ target.user.age }}세)</span
        >
        <table>
          <tbody>
            <td>
              <tr>
                <td>시</td>
                <td>일</td>
                <td>월</td>
                <td>년</td>

                <td></td>
                <td>시</td>
                <td>일</td>
                <td>월</td>
                <td>년</td>
              </tr>
              <!-- 천간 -->
              <tr>
                <td v-if="user.saju.timeSky">
                  <div>
                    <span :style="[user_time_sky, { backgroundColor: user.saju.timeSky.fiveCircleColor }]">{{
                      user.saju.timeSky.chinese
                    }}</span>
                    <span class="korean">{{ user.saju.timeSky.korean }}</span>
                  </div>

                  <div class="ten-star">{{ user.saju.timeSky.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[user_day_sky, { backgroundColor: user.saju.daySky.fiveCircleColor }]">{{
                      user.saju.daySky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.daySky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.daySky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_month_sky, { backgroundColor: user.saju.monthSky.fiveCircleColor }]">{{
                      user.saju.monthSky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.monthSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ user.saju.monthSky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_year_sky, { backgroundColor: user.saju.yearSky.fiveCircleColor }]">{{
                      user.saju.yearSky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.yearSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ user.saju.yearSky.tenStar }}</div>
                </td>

                <td></td>
                <!-- 천간 : 상대방 -->
                <td v-if="target.saju.timeSky">
                  <div>
                    <span :style="[target_time_sky, { backgroundColor: target.saju.timeSky.fiveCircleColor }]">{{
                      target.saju.timeSky.chinese
                    }}</span>
                    <span class="korean">{{ target.saju.timeSky.korean }}</span>
                  </div>

                  <div class="ten-star">{{ target.saju.timeSky.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[target_day_sky, { backgroundColor: target.saju.daySky.fiveCircleColor }]">{{
                      target.saju.daySky.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.daySky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ target.saju.daySky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[target_month_sky, { backgroundColor: target.saju.monthSky.fiveCircleColor }]">{{
                      target.saju.monthSky.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.monthSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ target.saju.monthSky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[target_year_sky, { backgroundColor: target.saju.yearSky.fiveCircleColor }]">{{
                      target.saju.yearSky.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.yearSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ target.saju.yearSky.tenStar }}</div>
                </td>
              </tr>

              <!-- 지지 -->
              <tr>
                <td v-if="user.saju.timeGround">
                  <div>
                    <span :style="[user_time_ground, { backgroundColor: user.saju.timeGround.fiveCircleColor }]">{{
                      user.saju.timeGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.timeGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.timeGround.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[user_day_ground, { backgroundColor: user.saju.dayGround.fiveCircleColor }]">{{
                      user.saju.dayGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.dayGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.dayGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_month_ground, { backgroundColor: user.saju.monthGround.fiveCircleColor }]">{{
                      user.saju.monthGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.monthGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.monthGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_year_ground, { backgroundColor: user.saju.yearGround.fiveCircleColor }]">{{
                      user.saju.yearGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.yearGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.yearGround.tenStar }}</div>
                </td>
                <td></td>
                <!-- 지지 : 상대방 -->
                <td v-if="target.saju.timeGround">
                  <div>
                    <span :style="[target_time_ground, { backgroundColor: target.saju.timeGround.fiveCircleColor }]">{{
                      target.saju.timeGround.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.timeGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ target.saju.timeGround.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[target_day_ground, { backgroundColor: target.saju.dayGround.fiveCircleColor }]">{{
                      target.saju.dayGround.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.dayGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ target.saju.dayGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[target_month_ground, { backgroundColor: target.saju.monthGround.fiveCircleColor }]"
                      >{{ target.saju.monthGround.chinese }}</span
                    >
                    <span class="korean"> {{ target.saju.monthGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ target.saju.monthGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[target_year_ground, { backgroundColor: target.saju.yearGround.fiveCircleColor }]">{{
                      target.saju.yearGround.chinese
                    }}</span>
                    <span class="korean"> {{ target.saju.yearGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ target.saju.yearGround.tenStar }}</div>
                </td>
              </tr>

              <!-- 지장간 -->
              <tr class="bottom">
                <td v-if="user.saju.timeGround">
                  <span>{{ user.saju.timeGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.timeGround.jijangGan.second">{{
                    user.saju.timeGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.timeGround.jijangGan.third.chinese }}</span>
                </td>
                <td v-else class="block"></td>
                <td>
                  <span>{{ user.saju.dayGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.dayGround.jijangGan.second">{{
                    user.saju.dayGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.dayGround.jijangGan.third.chinese }}</span>
                </td>

                <td>
                  <span>{{ user.saju.monthGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.monthGround.jijangGan.second">{{
                    user.saju.monthGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.monthGround.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ user.saju.yearGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.yearGround.jijangGan.second">{{
                    user.saju.yearGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.yearGround.jijangGan.third.chinese }}</span>
                </td>

                <td></td>
                <!-- 지장간 : 상대방 -->
                <td v-if="target.saju.timeGround">
                  <span>{{ target.saju.timeGround.jijangGan.first.chinese }}</span>
                  <span v-if="target.saju.timeGround.jijangGan.second">{{
                    target.saju.timeGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ target.saju.timeGround.jijangGan.third.chinese }}</span>
                </td>
                <td v-else class="block"></td>
                <td>
                  <span>{{ target.saju.dayGround.jijangGan.first.chinese }}</span>
                  <span v-if="target.saju.dayGround.jijangGan.second">{{
                    target.saju.dayGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ target.saju.dayGround.jijangGan.third.chinese }}</span>
                </td>

                <td>
                  <span>{{ target.saju.monthGround.jijangGan.first.chinese }}</span>
                  <span v-if="target.saju.monthGround.jijangGan.second">{{
                    target.saju.monthGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ target.saju.monthGround.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ target.saju.yearGround.jijangGan.first.chinese }}</span>
                  <span v-if="target.saju.yearGround.jijangGan.second">{{
                    target.saju.yearGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ target.saju.yearGround.jijangGan.third.chinese }}</span>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div v-if="chemy">
        <div v-for="item in chemy" :key="item.id">
          [{{ item.title }}] {{ item.name }}
          <v-btn small outlined @click="onClickPosition(item.positions, item.comment)">더보기</v-btn>
        </div>
        <v-textarea v-model="comment2" v-if="comment2" readonly rows="7"></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChemyFormatView",
  props: {
    user: {
      type: Object,
    },
    target: {
      type: Object,
    },
    chemy: {
      type: Array,
    },
    positions: {
      type: Array,
    },
    comment2: {
      type: String,
    },
  },
  created() {
    this.clearBorder();
  },
  data() {
    return {
      status: "fortune",
      // positions: [],
      comment: "",
      user_year_sky: { border: "" },
      user_month_sky: { border: "" },
      user_day_sky: { border: "" },
      user_time_sky: { border: "" },
      user_year_ground: { border: "" },
      user_month_ground: { border: "" },
      user_day_ground: { border: "" },
      user_time_ground: { border: "" },

      target_year_sky: { border: "" },
      target_month_sky: { border: "" },
      target_day_sky: { border: "" },
      target_time_sky: { border: "" },
      target_year_ground: { border: "" },
      target_month_ground: { border: "" },
      target_day_ground: { border: "" },
      target_time_ground: { border: "" },
    };
  },
  watch: {
    status: function() {
      this.comment = "";
      this.clearBorder();
    },
    positions: function() {
      this.clearBorder();
      for (var i in this.positions) {
        this.setBorder(this.positions[i]);
      }
    },
  },
  methods: {
    onClickPosition(positions, comment) {
      this.positions = positions;
      this.comment2 = comment;
    },
    clearBorder() {
      this.user_year_sky.border = "";
      this.user_month_sky.border = "";
      this.user_day_sky.border = "";
      this.user_time_sky.border = "";
      this.user_year_ground.border = "";
      this.user_month_ground.border = "";
      this.user_day_ground.border = "";
      this.user_time_ground.border = "";

      this.target_year_sky.border = "";
      this.target_month_sky.border = "";
      this.target_day_sky.border = "";
      this.target_time_sky.border = "";
      this.target_year_ground.border = "";
      this.target_month_ground.border = "";
      this.target_day_ground.border = "";
      this.target_time_ground.border = "";
    },
    setBorder(position) {
      switch (position) {
        case "user_year_sky":
          this.user_year_sky.border = "3px solid black";
          break;
        case "user_month_sky":
          this.user_month_sky.border = "3px solid black";
          break;
        case "user_day_sky":
          this.user_day_sky.border = "3px solid black";
          break;
        case "user_time_sky":
          this.user_time_sky.border = "3px solid black";
          break;

        case "user_year_ground":
          this.user_year_ground.border = "3px solid black";
          break;
        case "user_month_ground":
          this.user_month_ground.border = "3px solid black";
          break;
        case "user_day_ground":
          this.user_day_ground.border = "3px solid black";
          break;
        case "user_time_ground":
          this.user_time_ground.border = "3px solid black";
          break;

        case "target_year_sky":
          this.target_year_sky.border = "3px solid black";
          break;
        case "target_month_sky":
          this.target_month_sky.border = "3px solid black";
          break;
        case "target_day_sky":
          this.target_day_sky.border = "3px solid black";
          break;
        case "target_time_sky":
          this.target_time_sky.border = "3px solid black";
          break;

        case "target_year_ground":
          this.target_year_ground.border = "3px solid black";
          break;
        case "target_month_ground":
          this.target_month_ground.border = "3px solid black";
          break;
        case "target_day_ground":
          this.target_day_ground.border = "3px solid black";
          break;
        case "target_time_ground":
          this.target_time_ground.border = "3px solid black";
          break;
      }
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #90a4ae;
}
.ten-star {
  background-color: #90a4ae;
  font-size: 10px;
}

.korean {
  font-size: 10px;
}
.bottom {
  font-size: 10px;
}

table {
  border-collapse: collapse;
  width: 300px;
  border: 1px solid black;
  margin: 5px;
  font-size: 12px;
}

th,
td {
  width: 34px;
  border: 1px solid black;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 6em;
  z-index: 2;
  background-color: white;
}

.v-textarea {
  font-size: 1em;
}
</style>
