<template>
  <div class="saju-fortune-format-view" v-if="user">
    <div>
      <div class="fixed-bar">
        <span
          >{{ user.user.birthday }} (<span v-if="user.user.gender === 'male'">남</span><span v-else>여</span>,
          {{ user.user.age }}세)</span
        >
        <table>
          <tbody>
            <td>
              <tr>
                <td>시</td>
                <td>일</td>
                <td>월</td>
                <td>년</td>

                <td></td>
                <td>대운</td>
                <td>세운</td>
                <td>월운</td>
                <td>일운</td>
              </tr>
              <tr>
                <td v-if="user.saju.timeSky">
                  <div>
                    <span :style="[user_time_sky, { backgroundColor: user.saju.timeSky.fiveCircleColor }]">{{
                      user.saju.timeSky.chinese
                    }}</span>
                    <span class="korean">{{ user.saju.timeSky.korean }}</span>
                  </div>

                  <div class="ten-star">{{ user.saju.timeSky.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[user_day_sky, { backgroundColor: user.saju.daySky.fiveCircleColor }]">{{
                      user.saju.daySky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.daySky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.daySky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_month_sky, { backgroundColor: user.saju.monthSky.fiveCircleColor }]">{{
                      user.saju.monthSky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.monthSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ user.saju.monthSky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_year_sky, { backgroundColor: user.saju.yearSky.fiveCircleColor }]">{{
                      user.saju.yearSky.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.yearSky.korean }} </span>
                  </div>
                  <div class="ten-star">{{ user.saju.yearSky.tenStar }}</div>
                </td>

                <td></td>
                <td>
                  <div>
                    <span
                      :style="[big_fortune_sky, { backgroundColor: user.fortune.bigFortune.sky.fiveCircleColor }]"
                      >{{ user.fortune.bigFortune.sky.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.bigFortune.sky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.fortune.bigFortune.sky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[small_fortune_sky, { backgroundColor: user.fortune.smallFortune.sky.fiveCircleColor }]"
                      >{{ user.fortune.smallFortune.sky.chinese }}</span
                    >
                    <span class="korean">{{ user.fortune.smallFortune.sky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.fortune.smallFortune.sky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[month_fortune_sky, { backgroundColor: user.fortune.monthFortune.sky.fiveCircleColor }]"
                      >{{ user.fortune.monthFortune.sky.chinese }}</span
                    >
                    <span class="korean">{{ user.fortune.monthFortune.sky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.fortune.monthFortune.sky.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[day_fortune_sky, { backgroundColor: user.fortune.dayFortune.sky.fiveCircleColor }]"
                      >{{ user.fortune.dayFortune.sky.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.dayFortune.sky.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.fortune.dayFortune.sky.tenStar }}</div>
                </td>
              </tr>
              <tr>
                <td v-if="user.saju.timeGround">
                  <div>
                    <span :style="[user_time_ground, { backgroundColor: user.saju.timeGround.fiveCircleColor }]">{{
                      user.saju.timeGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.timeGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.timeGround.tenStar }}</div>
                </td>
                <td v-else class="block"></td>
                <td>
                  <div>
                    <span :style="[user_day_ground, { backgroundColor: user.saju.dayGround.fiveCircleColor }]">{{
                      user.saju.dayGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.dayGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.dayGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_month_ground, { backgroundColor: user.saju.monthGround.fiveCircleColor }]">{{
                      user.saju.monthGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.monthGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.monthGround.tenStar }}</div>
                </td>
                <td>
                  <div>
                    <span :style="[user_year_ground, { backgroundColor: user.saju.yearGround.fiveCircleColor }]">{{
                      user.saju.yearGround.chinese
                    }}</span>
                    <span class="korean"> {{ user.saju.yearGround.korean }}</span>
                  </div>
                  <div class="ten-star">{{ user.saju.yearGround.tenStar }}</div>
                </td>
                <td></td>

                <td>
                  <div>
                    <span
                      :style="[big_fortune_ground, { backgroundColor: user.fortune.bigFortune.ground.fiveCircleColor }]"
                      >{{ user.fortune.bigFortune.ground.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.bigFortune.ground.korean }}</span>
                  </div>
                  <div class="ten-star">
                    {{ user.fortune.bigFortune.ground.tenStar }}
                  </div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[
                        small_fortune_ground,
                        { backgroundColor: user.fortune.smallFortune.ground.fiveCircleColor },
                      ]"
                      >{{ user.fortune.smallFortune.ground.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.smallFortune.ground.korean }}</span>
                  </div>
                  <div class="ten-star">
                    {{ user.fortune.smallFortune.ground.tenStar }}
                  </div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[
                        month_fortune_ground,
                        { backgroundColor: user.fortune.monthFortune.ground.fiveCircleColor },
                      ]"
                      >{{ user.fortune.monthFortune.ground.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.monthFortune.ground.korean }}</span>
                  </div>
                  <div class="ten-star">
                    {{ user.fortune.monthFortune.ground.tenStar }}
                  </div>
                </td>
                <td>
                  <div>
                    <span
                      :style="[day_fortune_ground, { backgroundColor: user.fortune.dayFortune.ground.fiveCircleColor }]"
                      >{{ user.fortune.dayFortune.ground.chinese }}</span
                    >
                    <span class="korean"> {{ user.fortune.dayFortune.ground.korean }}</span>
                  </div>
                  <div class="ten-star">
                    {{ user.fortune.dayFortune.ground.tenStar }}
                  </div>
                </td>
              </tr>

              <tr class="bottom">
                <td v-if="user.saju.timeGround">
                  <span>{{ user.saju.timeGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.timeGround.jijangGan.second">{{
                    user.saju.timeGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.timeGround.jijangGan.third.chinese }}</span>
                </td>
                <td v-else class="block"></td>
                <td>
                  <span>{{ user.saju.dayGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.dayGround.jijangGan.second">{{
                    user.saju.dayGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.dayGround.jijangGan.third.chinese }}</span>
                </td>

                <td>
                  <span>{{ user.saju.monthGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.monthGround.jijangGan.second">{{
                    user.saju.monthGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.monthGround.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ user.saju.yearGround.jijangGan.first.chinese }}</span>
                  <span v-if="user.saju.yearGround.jijangGan.second">{{
                    user.saju.yearGround.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.saju.yearGround.jijangGan.third.chinese }}</span>
                </td>

                <td></td>
                <td>
                  <span>{{ user.fortune.bigFortune.ground.jijangGan.first.chinese }}</span>
                  <span v-if="user.fortune.bigFortune.ground.jijangGan.second">{{
                    user.fortune.bigFortune.ground.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.fortune.bigFortune.ground.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ user.fortune.smallFortune.ground.jijangGan.first.chinese }}</span>
                  <span v-if="user.fortune.smallFortune.ground.jijangGan.second">{{
                    user.fortune.smallFortune.ground.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.fortune.smallFortune.ground.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ user.fortune.monthFortune.ground.jijangGan.first.chinese }}</span>
                  <span v-if="user.fortune.monthFortune.ground.jijangGan.second">{{
                    user.fortune.monthFortune.ground.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.fortune.monthFortune.ground.jijangGan.third.chinese }}</span>
                </td>
                <td>
                  <span>{{ user.fortune.dayFortune.ground.jijangGan.first.chinese }}</span>
                  <span v-if="user.fortune.dayFortune.ground.jijangGan.second">{{
                    user.fortune.dayFortune.ground.jijangGan.second.chinese
                  }}</span>
                  <span v-else>#</span>
                  <span>{{ user.fortune.dayFortune.ground.jijangGan.third.chinese }}</span>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
      <div v-if="saju">
        <div v-if="type != 'FORTUNE'">
          <v-radio-group v-model="status" row>
            <v-radio label="사주원국" value="saju"></v-radio>
            <v-radio label="운세" value="fortune"></v-radio>
          </v-radio-group>
        </div>
        <div v-else><br /><br /></div>

        <div v-for="item in saju" :key="item.id">
          <span v-if="status === item.category">
            <span v-for="position in item.positions" :key="position.id">
              <v-btn v-if="position === 'big_fortune_sky'" x-small color="primary">대운 </v-btn>
              <v-btn v-else-if="position === 'big_fortune_ground'" x-small color="primary">대운</v-btn>
              <v-btn v-else-if="position === 'small_fortune_sky'" x-small color="warning">세운 </v-btn>
              <v-btn v-else-if="position === 'small_fortune_ground'" x-small color="warning">세운 </v-btn>
              <v-btn v-else-if="position === 'month_fortune_sky'" x-small color="error">월운 </v-btn>
              <v-btn v-else-if="position === 'month_fortune_ground'" x-small color="error">월운 </v-btn>
              <v-btn v-else-if="position === 'day_fortune_sky'" x-small color="success">일운 </v-btn>
              <v-btn v-else-if="position === 'day_fortune_ground'" x-small color="success">일운 </v-btn>
            </span>
            [{{ item.title }}] {{ item.name }}
            <v-btn small outlined @click="onClickPosition(item.positions, item.comment)">더보기</v-btn>
          </span>
        </div>

        <v-textarea v-model="comment2" v-if="comment2" readonly rows="7"></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SajuFortuneFormatView",
  props: {
    user: {
      type: Object,
    },
    saju: {
      type: Array,
    },
    positions: {
      type: Array,
    },
    comment2: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      status: "fortune",
      // positions: [],
      comment: "",
      user_year_sky: { border: "" },
      user_month_sky: { border: "" },
      user_day_sky: { border: "" },
      user_time_sky: { border: "" },
      user_year_ground: { border: "" },
      user_month_ground: { border: "" },
      user_day_ground: { border: "" },
      user_time_ground: { border: "" },
      big_fortune_sky: { border: "" },
      small_fortune_sky: { border: "" },
      month_fortune_sky: { border: "" },
      day_fortune_sky: { border: "" },
      big_fortune_ground: { border: "" },
      small_fortune_ground: { border: "" },
      month_fortune_ground: { border: "" },
      day_fortune_ground: { border: "" },
    };
  },
  watch: {
    status: function() {
      this.comment = "";
      this.clearBorder();
    },
    positions: function() {
      this.clearBorder();
      for (var i in this.positions) {
        this.setBorder(this.positions[i]);
      }
    },
  },
  created() {
    this.clearBorder();
    this.comment = "";
  },
  methods: {
    onClickPosition(positions, comment) {
      this.positions = positions;
      this.comment2 = comment;
    },
    clearBorder() {
      this.user_year_sky.border = "";
      this.user_month_sky.border = "";
      this.user_day_sky.border = "";
      this.user_time_sky.border = "";
      this.user_year_ground.border = "";
      this.user_month_ground.border = "";
      this.user_day_ground.border = "";
      this.user_time_ground.border = "";
      this.big_fortune_sky.border = "";
      this.small_fortune_sky.border = "";
      this.month_fortune_sky.border = "";
      this.day_fortune_sky.border = "";
      this.big_fortune_ground.border = "";
      this.small_fortune_ground.border = "";
      this.month_fortune_ground.border = "";
      this.day_fortune_ground.border = "";
    },
    setBorder(position) {
      switch (position) {
        case "user_year_sky":
          this.user_year_sky.border = "3px solid black";
          break;
        case "user_month_sky":
          this.user_month_sky.border = "3px solid black";
          break;
        case "user_day_sky":
          this.user_day_sky.border = "3px solid black";
          break;
        case "user_time_sky":
          this.user_time_sky.border = "3px solid black";
          break;

        case "user_year_ground":
          this.user_year_ground.border = "3px solid black";
          break;
        case "user_month_ground":
          this.user_month_ground.border = "3px solid black";
          break;
        case "user_day_ground":
          this.user_day_ground.border = "3px solid black";
          break;
        case "user_time_ground":
          this.user_time_ground.border = "3px solid black";
          break;

        case "big_fortune_sky":
          this.big_fortune_sky.border = "3px solid black";
          break;
        case "small_fortune_sky":
          this.small_fortune_sky.border = "3px solid black";
          break;
        case "month_fortune_sky":
          this.month_fortune_sky.border = "3px solid black";
          break;
        case "day_fortune_sky":
          this.day_fortune_sky.border = "3px solid black";
          break;

        case "big_fortune_ground":
          this.big_fortune_ground.border = "3px solid black";
          break;
        case "small_fortune_ground":
          this.small_fortune_ground.border = "3px solid black";
          break;

        case "month_fortune_ground":
          this.month_fortune_ground.border = "3px solid black";
          break;
        case "day_fortune_ground":
          this.day_fortune_ground.border = "3px solid black";
          break;
      }
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #90a4ae;
}
.ten-star {
  background-color: #90a4ae;
  font-size: 10px;
}

.korean {
  font-size: 10px;
}
.bottom {
  font-size: 10px;
}

table {
  border-collapse: collapse;
  width: 300px;
  border: 1px solid black;
  margin: 5px;
  font-size: 12px;
}

th,
td {
  width: 34px;
  border: 1px solid black;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 6em;
  z-index: 2;
  background-color: white;
}

.v-textarea {
  font-size: 1em;
}
</style>
