var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fortune-format-view"},[_c('div',[_c('table',[_c('tbody',[_c('td',[_vm._m(0),_c('tr',[_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.bigFortune.sky.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.bigFortune.sky.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.bigFortune.sky.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(_vm._s(_vm.fortune.bigFortune.sky.tenStar)+"("+_vm._s(_vm.fortune.bigFortune.sky.minusPlus)+")")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.smallFortune.sky.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.smallFortune.sky.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.smallFortune.sky.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.smallFortune.sky.tenStar)+"("+_vm._s(_vm.fortune.smallFortune.sky.minusPlus)+") ")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.monthFortune.sky.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.monthFortune.sky.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.monthFortune.sky.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.monthFortune.sky.tenStar)+"("+_vm._s(_vm.fortune.monthFortune.sky.minusPlus)+") ")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.dayFortune.sky.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.dayFortune.sky.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.dayFortune.sky.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(_vm._s(_vm.fortune.dayFortune.sky.tenStar)+"("+_vm._s(_vm.fortune.dayFortune.sky.minusPlus)+")")])])]),_c('tr',[_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.bigFortune.ground.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.bigFortune.ground.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.bigFortune.ground.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.bigFortune.ground.tenStar)+"("+_vm._s(_vm.fortune.bigFortune.ground.minusPlus)+") ")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.smallFortune.ground.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.smallFortune.ground.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.smallFortune.ground.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.smallFortune.ground.tenStar)+"("+_vm._s(_vm.fortune.smallFortune.ground.minusPlus)+") ")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.monthFortune.ground.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.monthFortune.ground.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.monthFortune.ground.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.monthFortune.ground.tenStar)+"("+_vm._s(_vm.fortune.monthFortune.ground.minusPlus)+") ")])]),_c('td',[_c('div',[_c('span',{style:([
                    {
                      backgroundColor: _vm.fortune.dayFortune.ground.fiveCircleColor,
                    } ])},[_vm._v(_vm._s(_vm.fortune.dayFortune.ground.chinese))]),_vm._v(" "+_vm._s(_vm.fortune.dayFortune.ground.korean)+" ")]),_c('div',{staticClass:"ten-star"},[_vm._v(" "+_vm._s(_vm.fortune.dayFortune.ground.tenStar)+"("+_vm._s(_vm.fortune.dayFortune.ground.minusPlus)+") ")])])]),_c('tr',{staticClass:"bottom"},[_c('td',[_vm._v(_vm._s(_vm.fortune.bigFortune.number)+"세")]),_c('td',[_vm._v(_vm._s(_vm.fortune.smallFortune.number)+"년")]),_c('td',[_vm._v(_vm._s(_vm.fortune.monthFortune.number)+"월")]),_c('td',[_vm._v(_vm._s(_vm.fortune.dayFortune.number)+"일")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v("대운")]),_c('td',[_vm._v("세운")]),_c('td',[_vm._v("월운")]),_c('td',[_vm._v("일운")])])}]

export { render, staticRenderFns }