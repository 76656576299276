<template>
  <v-app>
    <v-card>
      <v-app-bar app color="#fff59d">
        <v-icon color="black">mdi-yin-yang</v-icon>
        <strong class="logo" @click="onClickMainPage">SajuPick</strong>

        <v-spacer></v-spacer>

        <div v-if="isAuthorized">
          <span @click="onClickMyPage"> 내 정보</span>
        </div>
      </v-app-bar>

      <v-main app v-if="isAuthorized">
        <adfit-banner ad-unit="DAN-O5juSS7sub3XEt3K" data-ad-width="320" data-ad-height="50"> </adfit-banner>
        <router-view />
      </v-main>
      <v-main v-else>
        <adfit-banner ad-unit="DAN-O5juSS7sub3XEt3K" data-ad-width="320" data-ad-height="50"> </adfit-banner>
        <div class="intro">사주픽은 눈에 보이는 사주 & 운세 서비스입니다. (v1.0)</div>
        <sign-up-page />
      </v-main>

      <v-bottom-navigation v-if="isAuthorized" app fixed grow dark color="#fff59d">
        <v-btn bottom to="/mypage">
          <span>내 정보</span>
          <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-btn bottom to="/member">
          <span>멤버</span>
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>

        <v-btn bottom to="/group">
          <span>그룹</span>
          <v-icon>mdi-account-group</v-icon>
        </v-btn>

        <v-btn bottom to="/chemy">
          <span>궁합</span>
          <v-icon>mdi-tag-heart</v-icon>
        </v-btn>

        <v-btn bottom to="/fortune">
          <span>운세</span>
          <v-icon>mdi-calendar-star</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import SignUpPage from "./components/auth/SignUpPage";
export default {
  name: "App",
  components: {
    SignUpPage,
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    onClickMainPage() {
      this.$router.push({ path: "/" });
    },
    onClickMyPage() {
      this.$router.push({ path: "/mypage" });
    },
    onClickSignin() {
      this.$router.push({ path: "/signin" });
    },
  },
};
</script>

<style scoped>
.v-btn {
  margin-left: 5px;
}
.logo {
  margin-left: 5px;
  font-size: 20px;
}
.intro {
  margin: 10px;
  font-size: 15px;
}
</style>
