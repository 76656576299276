var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-text',[(_vm.status === 'signup')?_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('v-radio',{attrs:{"label":"남","value":"male"}}),_c('v-radio',{attrs:{"label":"여","value":"female"}})],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.birthdayType),callback:function ($$v) {_vm.birthdayType=$$v},expression:"birthdayType"}},[_c('v-radio',{attrs:{"label":"양력","value":"SOLAR"}}),_c('v-radio',{attrs:{"label":"음력","value":"LUNAR"}})],1),_c('v-text-field',{attrs:{"label":"* 생일 (예: 19950318) 필수","required":"","rules":[
                  function () { return !!_vm.birthday || 'This field is required'; },
                  function () { return (!!_vm.birthday && _vm.birthday.length == 8) || 'Birthday must be 8 characters'; },
                  function (v) { return /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g.test(v) ||
                    'Input 1900 ~ 2100 year'; } ],"type":"number"},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}}),_c('v-text-field',{attrs:{"label":"시간 (예: 0724)  선택","rules":[
                  function () { return !_vm.time || (!!_vm.time && _vm.time.length == 4) || 'Time must be 0 or 4 characters'; },
                  function (v) { return !_vm.time ||
                    /(0[0-9]|1[0-9]|2[0-3])(0[0-9]|[1-5][0-9])$/g.test(v) ||
                    'Input 00 ~ 23 hours, 00 ~ 59 minutes'; } ],"type":"number"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.onClickSignUp}},[_vm._v("사주보기")])],1),_c('br')],1):_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"uuid","required":"","rules":[
                  function () { return !!_vm.uuid || 'This field is required'; },
                  function (v) { return /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(v) ||
                    'Invalid uuid format'; } ]},model:{value:(_vm.uuid),callback:function ($$v) {_vm.uuid=$$v},expression:"uuid"}}),_c('v-text-field',{attrs:{"label":"* 생일 (예: 19950318) 필수","required":"","rules":[
                  function () { return !!_vm.birthday || 'This field is required'; },
                  function () { return (!!_vm.birthday && _vm.birthday.length == 8) || 'Birthday must be 8 characters'; },
                  function (v) { return /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g.test(v) ||
                    'Input 1900 ~ 2100 year'; } ],"type":"number"},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.onClickSignIn}},[_vm._v("로그인")])],1),_c('br')],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('v-radio',{attrs:{"label":"사주보기","value":"signup"}}),_c('v-radio',{attrs:{"label":"로그인","value":"signin"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }