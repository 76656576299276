<template>
  <div class="fortune-page">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="center" align="center">
                <v-btn icon outlined @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <div>
                <saju-fortune-format-view
                  :user="fortune.userManse"
                  :saju="fortune.fortune"
                  :positions="[]"
                  :comment2="comment"
                  :type="type"
                />
              </div>

              <br />
              <br />
              <v-row justify="center" align="center">
                <v-btn icon outlined text @click="dialog = false"> <v-icon>mdi-close</v-icon></v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="space">
      <v-select
        label="멤버 선택"
        :items="selectMemberOptions"
        item-text="label"
        item-value="value"
        v-model="memberId"
        :rules="[(v) => !!v || '멤버 선택 필요']"
        required
      ></v-select>

      <v-select
        label="연도 선택"
        :items="selectYearOptions"
        item-text="label"
        item-value="value"
        v-model="year"
        :rules="[(v) => !!v || '연도 선택 필요']"
        required
      ></v-select>
    </div>

    <v-row class="button"><v-btn outlined small class="space" @click="onClickFortune">운세 분석</v-btn></v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SajuFortuneFormatView from "../format/SajuFortuneFormatView";

export default {
  name: "FortunePage",
  data() {
    return {
      dialog: false,
      year: Number(this.$moment().format("YYYY")),
      comment: "",
      type: "FORTUNE",
      memberId: "",
    };
  },
  components: {
    SajuFortuneFormatView,
  },
  watch: {
    comment: {
      deep: true,
      async handler() {
        this.comment = "";
      },
    },
  },
  async created() {
    await this.getMemberList();
    this.memberId = this.memberList[this.memberList.length - 1].id;
    this.year = Number(this.$moment().format("YYYY"));
    this.comment = "";
  },

  methods: {
    onClickFortune() {
      this.type = "FORTUNE";
      this.dialog = true;
      this.comment = "";
      this.getFortune(this);
    },
    ...mapActions(["getMemberList", "getFortune"]),
  },
  computed: {
    selectMemberOptions() {
      return this.memberList
        ? this.memberList.map((member) => ({
            label: member.gender == "male" ? `${member.birthday} (남)` : `${member.birthday} (여)`,
            value: member.id,
          }))
        : [];
    },
    selectYearOptions() {
      const yearObj = [{ year: 2022 }, { year: 2021 }];
      return yearObj
        ? yearObj.map((year) => ({
            label: year.year,
            value: year.year,
          }))
        : [];
    },
    ...mapState(["memberList", "fortune"]),
  },
};
</script>

<style scoped>
.container {
  max-width: 100vw;
  padding: 3px;
  margin: 3px;
}
.my-page {
  margin: 1px;
}
.space {
  margin: 3px;
}
.button {
  margin: 20px;
}
.name {
  margin: 5px;
}
.manse-format-view {
  margin: 10px;
}
</style>
