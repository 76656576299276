<template>
  <div>
    <v-card>
      <v-form ref="form">
        <v-card-text>
          <v-container v-if="status === 'signup'">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="4">
                <v-radio-group v-model="gender" row>
                  <v-radio label="남" value="male"></v-radio>
                  <v-radio label="여" value="female"></v-radio>
                </v-radio-group>
                <v-radio-group v-model="birthdayType" row>
                  <v-radio label="양력" value="SOLAR"></v-radio>
                  <v-radio label="음력" value="LUNAR"></v-radio>
                </v-radio-group>

                <v-text-field
                  label="* 생일 (예: 19950318) 필수"
                  required
                  v-model="birthday"
                  :rules="[
                    () => !!birthday || 'This field is required',
                    () => (!!birthday && birthday.length == 8) || 'Birthday must be 8 characters',
                    (v) =>
                      /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g.test(v) ||
                      'Input 1900 ~ 2100 year',
                  ]"
                  type="number"
                ></v-text-field>
                <v-text-field
                  label="시간 (예: 0724)  선택"
                  v-model="time"
                  :rules="[
                    () => !time || (!!time && time.length == 4) || 'Time must be 0 or 4 characters',
                    (v) =>
                      !time ||
                      /(0[0-9]|1[0-9]|2[0-3])(0[0-9]|[1-5][0-9])$/g.test(v) ||
                      'Input 00 ~ 23 hours, 00 ~ 59 minutes',
                  ]"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-btn outlined @click="onClickSignUp">사주보기</v-btn>
            </v-row>
            <br />
          </v-container>
          <v-container v-else>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="uuid"
                  required
                  v-model="uuid"
                  :rules="[
                    () => !!uuid || 'This field is required',
                    (v) =>
                      /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(v) ||
                      'Invalid uuid format',
                  ]"
                ></v-text-field>
                <v-text-field
                  label="* 생일 (예: 19950318) 필수"
                  required
                  v-model="birthday"
                  :rules="[
                    () => !!birthday || 'This field is required',
                    () => (!!birthday && birthday.length == 8) || 'Birthday must be 8 characters',
                    (v) =>
                      /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g.test(v) ||
                      'Input 1900 ~ 2100 year',
                  ]"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-btn outlined @click="onClickSignIn">로그인</v-btn>
            </v-row>
            <br />
          </v-container>

          <v-radio-group v-model="status" row>
            <v-radio label="사주보기" value="signup"></v-radio>
            <v-radio label="로그인" value="signin"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SignUpForm",
  data() {
    return {
      uuid: "",
      status: "signup",
      birthday: "",
      birthdayType: "SOLAR",
      gender: "male",
      time: "",
    };
  },
  methods: {
    onClickSignUp() {
      if (this.$refs.form.validate() == false) {
        return;
      }

      this.$emit("signup", this);
    },
    onClickSignIn() {
      if (this.$refs.form.validate() == false) {
        return;
      }

      this.$emit("signin", this);
    },
  },
};
</script>
