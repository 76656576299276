import Vue from "vue";
import Router from "vue-router";
import MyPage from "@/components/auth/MyPage";
import MainPage from "@/components/auth/MainPage";
import MemberPage from "@/components/member/MemberPage";
import ChemyPage from "@/components/chemy/ChemyPage";
import FortunePage from "@/components/fortune/FortunePage";
import GroupPage from "@/components/group/GroupPage";
import GroupMemberPage from "@/components/group-member/GroupMemberPage";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //메인페이지
    {
      path: "/",
      component: MainPage,
      name: "MainPage",
    },
    //마이페이지
    {
      path: "/mypage",
      component: MyPage,
      name: "MyPage",
    },
    //멤버
    {
      path: "/member",
      component: MemberPage,
      name: "MemberPage",
    },
    //궁합
    {
      path: "/chemy",
      component: ChemyPage,
      name: "ChemyPage",
    },
    //운세
    {
      path: "/fortune",
      component: FortunePage,
      name: "FortunePage",
    },
    //그룹
    {
      path: "/group",
      component: GroupPage,
      name: "GroupPage",
    },
    //그룹-멤버
    {
      path: "/group-member/:groupId",
      component: GroupMemberPage,
      name: "GroupMemberPage",
      props: true,
    },
  ],
});
