import axios from "axios";

console.log(process.env.NODE_ENV);
export default process.env.NODE_ENV == "production"
  ? axios.create({
      //production 환경 (AWS ECS)
      baseURL: "https://saju.app",
      // baseURL: "http://13.124.81.23",
      // baseURL: "http://sajupick-lb-230746530.ap-northeast-2.elb.amazonaws.com",
    })
  : axios.create({
      //development 환경
      baseURL: "http://localhost:3000",
    });
