<template>
  <tr class="member-list-view" v-if="type !== 'group'">
    <!-- 멤버 리스트 -->
    <td>{{ index }}</td>
    <td>
      {{ member.Member.birthday }}(<span v-if="member.Member.gender === 'male'">남</span> <span v-else>여</span>,
      {{ member.Member.age }})<br />(<span v-if="member.Member.birthdayType === 'SOLAR'">양력</span
      ><span v-else>음력</span>){{ member.Member.time }}
    </td>
    <td v-if="member.type === 'MEMBER'">
      <member-to-group-form @memberToGroupAdd="onClickMemberToGroupAdd" :member="member.Member" />
      <member-delete-form @memberDelete="onClickMemberDelete" :member="member.Member" />
    </td>
    <td v-else>
      <div><member-to-group-form @memberToGroupAdd="onClickMemberToGroupAdd" :member="member.Member" /></div>
      <div>본인</div>
    </td>
    <td>
      <v-btn justify-center outlined x-small @click="onClickSaju">사주</v-btn
      ><v-btn justify-center outlined x-small @click="onClickManse">만세력</v-btn>
    </td>
  </tr>
  <tr v-else>
    <!-- 그룹-멤버 리스트 -->
    <td>{{ index }}</td>
    <td>
      {{ member.birthday }}(<span v-if="member.gender === 'male'">남</span> <span v-else>여</span>,
      {{ member.age }})<br />(<span v-if="member.birthdayType === 'SOLAR'">양력</span><span v-else>음력</span>){{
        member.time
      }}
    </td>
    <td><member-remove-form @memberRemove="onClickMemberRemove" :member="member" :group="groupId" /></td>
    <td><v-btn justify-center outlined x-small @click="onClickManse">만세력</v-btn></td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import MemberDeleteForm from "./MemberDeleteForm";
import MemberRemoveForm from "../group-member/MemberRemoveForm";
import MemberToGroupForm from "../group-member/MemberToGroupForm";

export default {
  name: "MemberListView",
  components: {
    MemberDeleteForm,
    MemberRemoveForm,
    MemberToGroupForm,
  },
  data() {
    return {
      id: this.member.Member ? this.member.Member.id : this.member.id,
    };
  },
  props: {
    page: {
      type: Number,
    },
    member: {
      type: Object,
    },
    index: {
      type: Number,
    },
    type: {
      type: String,
    },
    groupId: {
      type: Number,
    },
    memberId: {
      type: Number,
    },
  },
  methods: {
    async onClickMemberToGroupAdd(payload) {
      await this.addMemberToGroup(payload);
    },
    async onClickMemberDelete(payload) {
      await this.deleteMember(payload);
      await this.getMembers(this);
    },
    async onClickMemberRemove(payload) {
      await this.removeMemberFromGroup(payload);
      await this.getGroupMembers(this);
    },

    onClickSaju() {
      this.memberId = this.member.Member ? this.member.Member.id : this.member.id;
      this.type = "SAJU";
      this.dialog = true;
      this.$emit("emitSaju", this);
    },
    onClickManse() {
      this.memberId = this.member.Member ? this.member.Member.id : this.member.id;
      this.type = "MANSE";
      this.dialog = true;
      this.$emit("emitManse", this);
    },

    ...mapActions(["deleteMember", "getMembers", "removeMemberFromGroup", "getGroupMembers", "addMemberToGroup"]),
  },
};
</script>
