export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_MY_INFO = "SET_MY_INFO";
export const DESTROY_ACCESS_TOKEN = "DESTROY_ACCESS_TOKEN";
export const DESTROY_MY_INFO = "DESTROY_MY_INFO";
export const SET_SAJU = "SET_SAJU";
export const SET_MANSE = "SET_MANSE";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBER_LIST = "SET_MEMBER_LIST";
export const SET_CHEMY = "SET_CHEMY";
export const SET_FORTUNE = "SET_FORTUNE";
export const SET_GROUPS = "SET_GROUPS";
export const SET_GROUP_MEMBERS = "SET_GROUP_MEMBERS";
export const SET_GROUP_NAMES = "SET_GROUP_NAMES";
