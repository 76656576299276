<template>
  <div class="chemy-page">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="center" align="center">
                <v-btn icon outlined @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>

              <chemy-format-view
                :user="chemy.userManse"
                :target="chemy.targetManse"
                :chemy="chemy.chemy"
                :positions="[]"
                :comment2="comment"
              />
              <br />
              <br />
              <v-row justify="center" align="center">
                <v-btn icon outlined text @click="dialog = false"> <v-icon>mdi-close</v-icon></v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <div class="space">
      <v-select
        label="멤버1 선택"
        :items="selectMemberOptions"
        item-text="label"
        item-value="value"
        v-model="memberId"
        :rules="[(v) => !!v || '멤버1 선택 필요']"
        required
      ></v-select>

      <v-select
        label="멤버2 선택"
        :items="selectMemberOptions"
        item-text="label"
        item-value="value"
        v-model="targetId"
        :rules="[(v) => !!v || '멤버2 선택 필요']"
        required
      ></v-select>
    </div>

    <v-row class="button"><v-btn outlined small class="space" @click="onClickChemy">궁합 분석</v-btn></v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ChemyFormatView from "../format/ChemyFormatView";

export default {
  name: "ChemyPage",
  data() {
    return {
      dialog: false,
      comment: "",
      type: "",
      memberId: "",
      targetId: "",
    };
  },
  watch: {
    comment: {
      deep: true,
      async handler() {
        this.comment = "";
      },
    },
  },
  async created() {
    await this.getMemberList();
    this.memberId = this.memberList[this.memberList.length - 1].id;
    this.targetId = this.memberList[this.memberList.length - 1].id;
  },
  components: {
    ChemyFormatView,
  },
  methods: {
    async onClickChemy() {
      this.type = "CHEMY";
      this.dialog = true;
      await this.getChemy(this);
    },
    ...mapActions(["getChemy", "getMemberList"]),
  },
  computed: {
    selectMemberOptions() {
      return this.memberList
        ? this.memberList.map((member) => ({
            label: member.gender == "male" ? `${member.birthday} (남)` : `${member.birthday} (여)`,
            value: member.id,
          }))
        : [];
    },
    ...mapState(["me", "chemy", "memberList"]),
  },
};
</script>

<style scoped>
.container {
  max-width: 100vw;
  padding: 3px;
  margin: 3px;
}
.my-page {
  margin: 1px;
}
.space {
  margin: 3px;
}
.button {
  margin: 20px;
}
.name {
  margin: 5px;
}
.manse-format-view {
  margin: 10px;
}
</style>
